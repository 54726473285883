
















































































































import { Component, Provide } from 'vue-property-decorator';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import StandardModal from '@/components/modals/StandardModal.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { mixins } from 'vue-class-component';
import BreakpointWrapper from '@/components/wrappers/BreakpointWrapper';
import { namespace } from 'vuex-class';
import UiOnboarding from '@/models/graphql/UiOnboarding';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import ButtonIconComponent from '@/components/ButtonIconComponent.vue';

const uiOnboardingStore = namespace('UiOnboardingStore');

@Component({
  components: {
    ButtonIconComponent,
    FontAwesomeComponent,
    ButtonComponent,
    StandardModal,
  },
})
export default class UiOnBoardingWidget extends mixins(VueRegisterStoreWidget, BreakpointWrapper) {
  baseStoreName = 'UiOnBoardingWidgetStore';

  @Provide('context')
  private context = 'onboarding';

  private modalId = `ui-on-boarding-${this.widget.id}-modal`;

  @uiOnboardingStore.Action
  private addSeenOnBoarding!: (onBoarding: string) => Promise<UiOnboarding | undefined>;

  private get totalSteps(): number {
    return this.$store.state.WidgetDispatcherStore[`${this.storeName}`].totalSteps;
  }

  private get currentStep(): number {
    return this.$store.state.WidgetDispatcherStore[`${this.storeName}`].currentStep;
  }

  created(): void {
    this.widget.subWidgets.forEach((onboarding) => {
      this.setOnboardingPositions(onboarding.uid);
    });
    this.setTotalSteps(this.widget.subWidgets.length);
    this.setDataConfig();
  }

  private setOnboardingPositions(onboarding: string): void {
    this.$store.commit(`${this.widgetStorePath}/setOnboardingPositions`, onboarding);
  }

  private setTotalSteps(steps: number): void {
    this.$store.commit(`${this.widgetStorePath}/setTotalSteps`, steps);
  }

  private nextStep(): void {
    this.$store.commit(`${this.widgetStorePath}/nextStep`);
  }

  private prevStep(): void {
    this.$store.commit(`${this.widgetStorePath}/prevStep`);
  }

  private submit(): void {
    this.addSeenOnBoarding(this.widget.uid);
    this.$bvModal.hide(this.modalId);
  }
}
